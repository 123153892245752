// forEach polyfill - IE 11
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

let lazyLoadInstance;


const oxid = {

    // Popups
    popup: {
        load(customClass, overlayText) {
            if (!$('body').hasClass('no-basket-loader')) {
                oxid.mask.load(customClass, overlayText);
            }
        },
        show() {
            oxid.mask.off();
            $('#basketAdded').modal();
        },

    },

    // Tags
    tags: {

        input: null,

        select() {
            oxid.tags.input.value += ` ${this.innerHTML}`;
            this.className = 'sel';
            this.removeAttribute('onclick');
        },

        addSelect(id, input) {
            const tg = document.getElementById(id);
            const ed = document.getElementById(input);
            if (tg && ed) {
                oxid.tags.input = ed;
                for (let i = 0; i < tg.childNodes.length; i += 1) {
                    if (tg.childNodes[i].tagName && tg.childNodes[i].tagName.toUpperCase() === 'A') {
                        tg.childNodes[i].onclick = oxid.tags.select;
                        tg.childNodes[i].removeAttribute('href');
                    }
                }
            }
        },
    },

    // Forms
    form: {

        // send
        send(form) {
            const myForm = document.forms[form];
            if (myForm) { myForm.submit(); }
        },

        // submits form + changes cl, fnc, formReload values
        reload(stop, form, cl, fnc) {
            if (stop) { return; }
            const myForm = document.forms[form];
            if (myForm) {
                myForm.elements.cl.value = cl;
                myForm.elements.fnc.value = fnc;
                myForm.submit();
            }
        },

        // clears form values using given regex on fileld name
        clear(stop, form, pattern) {
            if (stop) { return; }
            const fields = document.forms[form].elements; let
                i;
            if (fields) {
                for (i = 0; i < fields.length; i += 1) {
                    if (pattern.test(fields[i].name)) {
                        if (fields[i].tagName.toUpperCase() === 'INPUT') { fields[i].value = ''; }
                        if (fields[i].tagName.toUpperCase() === 'SELECT') { fields[i].item(0).selected = true; }
                    }
                }
            }
        },

        select(id, value) {
            const el = document.getElementsByName(id);
            if (el) { el[value].checked = 'true'; }
        },

        set(id, value) {
            const el = document.getElementsByName(id);
            if (el) {
                el.value = value;
            }
        },

    },

    // Review / Rating
    review: {
        show() {
            oxid.showhideblock('write_review', true);
            oxid.showhideblock('write_new_review', false);
        },

        rate(value) {
            oxid.review.show();
            const myForm = document.getElementById('rating');
            if (myForm !== null) {
                if (myForm.artrating) {
                    myForm.artrating.value = value;
                }

                document.getElementById('js-detail-rating-bottom').setAttribute('data-stars', value);
            }
        },
    },

    // SelectList
    sellist: {
        set(name, value) {
            // selectlist
            const sList = document.getElementById('slist');
            if (sList !== null) {
                sList.href = `${sList.href}&${name}=${value}`;
            }
            // wishlist
            const wList = document.getElementById('wlist');
            if (wList !== null) {
                wList.href = `${wList.href}&${name}=${value}`;
            }
        },
    },

    // etc...
    showhide(id, show) {
        const el = document.getElementById(id);
        if (el) { el.style.display = show ? '' : 'none'; }
    },

    showhideblock(id, show) {
        const el = document.getElementById(id);
        if (el) { el.style.display = show ? 'block' : 'none'; }
    },

    getEventTarget(e) {
        let targ;

        if (e.target) {
            targ = e.target;
        } else if (e.srcElement) {
            targ = e.srcElement;
        }

        // Safari
        if (targ.nodeType === 3) {
            targ = targ.parentNode;
        }

        return targ;
    },

    mdVariants: {
        // reloading page by selected value in select list
        getMdVariantUrl(selId) {
            oxid.mask.load();
            const mdVar = document.getElementById(selId);
            let newUrl;

            if (mdVar) {
                newUrl = mdVar.options[mdVar.selectedIndex].value;
            }

            if (newUrl) {
                document.location.href = newUrl;
            }
        },

        mdAttachAll() {
            if (!mdVariantSelectIds) {
                mdVariantSelectIds = [];
            }

            for (let i = 0; i < mdVariantSelectIds.length; i += 1) {
                if (mdVariantSelectIds[i]) {
                    for (let j = 0; j < mdVariantSelectIds[i].length; j += 1) {
                        // attach JS handlers
                        const mdSelect = document.getElementById(mdVariantSelectIds[i][j]);
                        if (mdSelect) {
                            mdSelect.onchange = oxid.mdVariants.resetMdVariantSelection;
                        }
                    }
                }
            }
        },

        resetMdVariantSelection(e) {
            const mdSelect = oxid.getEventTarget(e);
            // hide all
            const selectedValue = mdSelect.options[mdSelect.selectedIndex].value;
            const level = oxid.mdVariants.getSelectLevel(mdSelect.id);

            if (level !== null) {
                oxid.mdVariants.hideAllMdSelect(level + 1);
            }
            // show selection
            let showId = selectedValue;
            while (showId) {
                const showSelectId = oxid.mdVariants.getMdSelectNameById(showId);
                oxid.mdVariants.showMdSelect(showSelectId);
                const shownSelect = document.getElementById(showSelectId);
                if (shownSelect) {
                    showId = shownSelect.options[shownSelect.selectedIndex].value;
                } else {
                    showId = null;
                }
            }

            oxid.mdVariants.showMdRealVariant();
        },

        getMdSelectNameById(id) {
            const name = `mdvariantselect_${id}`;
            return name;
        },

        getSelectLevel(name) {
            for (let i = 0; i < mdVariantSelectIds.length; i += 1) {
                for (let j = 0; j < mdVariantSelectIds[i].length; j += 1) {
                    if (mdVariantSelectIds[i][j] === name) {
                        return i;
                    }
                }
            }
            return null;
        },

        showMdSelect(id) {
            if (document.getElementById(id)) {
                document.getElementById(id).style.display = 'inline';
            }
        },

        hideAllMdSelect(level) {
            for (let i = level; i < mdVariantSelectIds.length; i += 1) {
                if (mdVariantSelectIds[i]) {
                    for (let j = 0; j < mdVariantSelectIds[i].length; j += 1) {
                        if (document.getElementById(mdVariantSelectIds[i][j])) {
                            document.getElementById(mdVariantSelectIds[i][j]).style.display = 'none';
                        }
                    }
                }
            }
        },

        getSelectedMdRealVariant() {
            for (let i = 0; i < mdVariantSelectIds.length; i += 1) {
                for (let j = 0; j < mdVariantSelectIds[i].length; j += 1) {
                    const mdSelectId = mdVariantSelectIds[i][j];
                    const mdSelect = document.getElementById(mdSelectId);
                    if (mdSelect && mdSelect.style.display === 'inline') {
                        const selectedVal = mdSelect.options[mdSelect.selectedIndex].value;
                        if (mdRealVariants[selectedVal]) return mdRealVariants[selectedVal];
                    }
                }
            }
            return false;
        },

        showMdRealVariant() {
            document.getElementById('md_variant_box').innerHTML = '';
            const selectedId = oxid.mdVariants.getSelectedMdRealVariant();
            if (selectedId && document.getElementById(`mdvariant_${selectedId}`)) {
                document.getElementById('md_variant_box').innerHTML = document.getElementById(`mdvariant_${selectedId}`).innerHTML;
            }
        },
    },

    stateSelector: {

        fillStates(
            countrySelectId,
            stateSelectId,
            divId,
            allStates,
            allStateIds,
            allCountryIds,
            statePromptString,
            selectedStateId,
        ) {
            const countrySelect = document.getElementById(countrySelectId);
            const states = allStates[allCountryIds[countrySelect.options[countrySelect.selectedIndex].value]];
            const ids = allStateIds[allCountryIds[countrySelect.options[countrySelect.selectedIndex].value]];
            const stateSelectObject = document.getElementById(stateSelectId);
            let option;

            if (stateSelectObject == null) {
                return;
            }

            // add event handler to country select (this is important for the first time)
            document.getElementById(countrySelectId).onchange = function () {
                oxid.stateSelector.fillStates(
                    countrySelectId,
                    stateSelectId,
                    divId,
                    allStates,
                    allStateIds,
                    allCountryIds,
                    statePromptString,
                    selectedStateId,
                );
            };

            // remove all nodes
            if (stateSelectObject.hasChildNodes()) {
                while (stateSelectObject.childNodes.length >= 1) {
                    stateSelectObject.removeChild(stateSelectObject.firstChild);
                }
            }

            // create blank prompt option
            option = document.createElement('option');
            option.appendChild(document.createTextNode(statePromptString));
            option.setAttribute('value', '');
            stateSelectObject.appendChild(option);

            // fill options with states
            if (states != null) {
                for (let x = 0; x < states.length; x += 1) {
                    option = document.createElement('option');
                    option.appendChild(document.createTextNode(states[x]));
                    option.setAttribute('value', ids[x]);
                    stateSelectObject.appendChild(option);
                    if (selectedStateId === ids[x]) {
                        stateSelectObject.selectedIndex = x + 1;
                    }
                }
            }

            oxid.showhideblock(divId, states != null && states.length > 0);
        },
    },
    mask: {
        load(customClass, overlayText) {
            if ($('.modal-backdrop').length === 0) {
                $(`
                    <div class="modal-backdrop fade show loading ${customClass}">
                        <div class="modal-backdrop-list">
                            <span class="modal-backdrop-bg">${overlayText || ''}</span>
                        </div>
                    </div>
                `).appendTo(document.body);
            } else {
                $('.modal-backdrop').addClass('loading');
            }
        },

        on() {
            if ($('.modal-backdrop').length === 0) {
                $('<div class="modal-backdrop fade show"></div>').appendTo(document.body);
            } else if ($('.modal-backdrop').hasClass('loading')) {
                $('.modal-backdrop').removeClass('loading');
            }
        },
        off() {
            $('.modal-backdrop').remove();
        },
    },
    setClass(id, pcl) {
        const el = document.getElementById(id);

        if (el) {
            el.className = pcl;
        }
    },
    responsive: {
        xs: window.matchMedia('(max-width: 575px)'),
        sm: window.matchMedia('(min-width: 576px) and (max-width: 767px)'),
        md: window.matchMedia('(min-width: 768px) and (max-width: 991px)'),
        lg: window.matchMedia('(min-width: 992px) and (max-width: 1999px)'),
        lgOnly: window.matchMedia('(min-width: 992px) and (max-width: 1338px)'),
        xl: window.matchMedia('(min-width: 1338px)'),
        breakpointXs: 576,
        breakpointSm: 768,
        breakpointMd: 992,
        breakpointLg: 1338,
    },
};
/* cookies and localStorage helper functions */

/**
 * Function will POST value to the oxid class and function.
 */
function postLink() {
    const postLinks = document.querySelectorAll('a[data-postlink]');

    postLinks.forEach((element) => {
        element.addEventListener('click', (event) => {
            event.preventDefault();

            const config = JSON.parse(element.getAttribute('data-postlink'));

            $.ajax({
                url: '/index.php',
                method: 'POST',
                data: config,
            })
                .then((response) => {
                    if (response.success) {
                        window.location.reload();
                        return;
                    }

                    throw response.message;
                })
                .catch(() => {
                    window.location.reload();
                });
        });
    });
}
postLink();

function readCookie(cookieName) { // eslint-disable-line no-unused-vars
    const theCookie = `${document.cookie}`;
    const ind = theCookie.indexOf(cookieName);
    if (ind === -1 || cookieName === '') return '';
    let ind1 = theCookie.indexOf(';', ind);
    if (ind1 === -1) ind1 = theCookie.length;
    return unescape(theCookie.substring(ind + cookieName.length + 1, ind1));
}

function createCookie(name, value, seconds) {
    let expires;

    if (seconds) {
        const date = new Date();
        date.setTime(date.getTime() + (seconds * 1000));
        expires = `; expires=${date.toGMTString()}`;
    } else {
        expires = '';
    }
    document.cookie = `${name}=${value}${expires}; path=/`;
}

function eraseCookie(name) { // eslint-disable-line no-unused-vars
    createCookie(name, '', -1);
}

function supportsLocalStorage() { // eslint-disable-line no-unused-vars
    const testKey = 'test'; const
        storage = window.sessionStorage;
    try {
        storage.setItem(testKey, '1');
        storage.removeItem(testKey);
        return true;
    } catch (error) {
        return false;
    }
}

function initCustomPopover() {
    $('[data-toggle="popover"]').popover({
        trigger: 'manual',
        html: true,
        animation: false,
    })
        .on('mouseenter', function () {
            $(this).popover('show');
            $('.popover').on('mouseleave', () => {
                $(this).popover('hide');
            });
        }).on('mouseleave', function () {
            setTimeout(() => {
                if (!$('.popover:hover').length) {
                    $(this).popover('hide');
                }
            }, 300);
        });
}

$(() => {
    initCustomPopover();
});

$(() => {
    if (oxid.responsive.xl.matches) {
        const $categories = $('#js-main-menu-list');
        const openClass = 'open';
        const activeClass = 'active';
        const item = '.nav-item';
        const $pageOverlay = $('.page-overlay');

        $categories.menuAim({
            activateCallback(row) {
                if ($(row).is('.js-dropdown-menu')) {
                    lazyLoadInstance.update(); /* eslint-disable-line no-undef */
                    $(row).addClass(openClass);
                    $categories.addClass(openClass);
                    $pageOverlay.addClass(activeClass);
                }
            },
            deactivateCallback() {
                $categories.removeClass(openClass);
                $categories.find(item).removeClass(openClass);
                $pageOverlay.removeClass(activeClass);
            },
            exitMenuCallback() {
                $categories.removeClass(openClass);
                $categories.find(item).removeClass(openClass);
                $pageOverlay.removeClass(activeClass);
            },
            submenuDirection: 'right',
            activationDelay: 0,
        });
    }
});

function lightGalleryInit($lightGallery) {
    $lightGallery.lightGallery({
        thumbWidth: 48,
        thumbContHeight: 48,
        selector: '.zoom',
    });
}

$(() => {
    lightGalleryInit($('.js-lightgallery'));
});

function initSpinner(spinnerElement) {
    let $spinnerElement = $('.spinner');
    const defaultSpinnerConfig = {
        textAlign: 'center',
        autoDelay: 500,
        autoInterval: 100,
        boostThreshold: 10,
        boostMultiplier: 'auto',
        locale: null,
        template:
            `
                <div>
                    <button class="btn btn-decrement" type="button" data-spinner-button data-spinner-decrement-button></button>
                    <input type="text" inputmode="decimal" class="form-control" data-spinner-loaded data-lpignore="true"/>
                    <button class="btn btn-increment" type="button" data-spinner-button></button>
                </div>
            `,
    };

    if (spinnerElement) {
        $spinnerElement = spinnerElement;
    }

    $spinnerElement.inputSpinner(defaultSpinnerConfig);
}

initSpinner();

const vouchers = {
    colorBox: (button, status, classes, timeout) => {
        button.addClass(classes);
        setTimeout(() => {
            button.removeClass(classes);
        }, timeout);
    },

    initVouchers: () => {
        const buttons = document.querySelectorAll('[data-discount-copy]');

        buttons.forEach((item) => {
            const thisBtn = item;
            // eslint-disable-next-line no-undef
            const clipboard = new ClipboardJS(thisBtn);
            const delay = 3000;

            clipboard.on('success', () => {
                vouchers.colorBox($(thisBtn), 'success', 'border border-success', delay);
            });

            clipboard.on('error', () => {
                vouchers.colorBox($(thisBtn), 'error', 'border border-danger', delay);
            });
        });
    },
};

vouchers.initVouchers();

$(() => {
    $('.locator .loadpage').on('click', () => {
        const nextLink = $('.locator .pagination').find('.active').next('li').find('a')
            .attr('href');

        if (nextLink) {
            oxid.mask.load();
            window.history.pushState(nextLink, 'url', nextLink);

            $.ajax({
                url: nextLink,
                type: 'GET',
            })
                .done((data) => {
                    const newLocator = $(data).find('.locator .pagination').first().html();
                    const newProducts = $(data).find('.load-content-wrap').html();
                    const loadPageBtn = $('.loadpage');
                    const dataLayerNameGA3 = loadPageBtn.data('datalayername-ga3');
                    const dataLayerNameGA4 = loadPageBtn.data('datalayername-ga4');
                    const dataLayerName = loadPageBtn.data('datalayername');
                    const dataLayer = window[dataLayerName];
                    const pushDataGA3 = window[dataLayerNameGA3];
                    const pushDataGA4 = window[dataLayerNameGA4];

                    $('.locator-row').each((index, element) => {
                        $(element).find('.pagination').html(newLocator);
                    });

                    const newNextLink = $('.locator .pagination').find('.active').next('li').find('.page-link')
                        .attr('href');
                    const nextCountText = $(data).find('[data-loadpage-btn]').text();

                    loadPageBtn.attr('href', newNextLink);
                    loadPageBtn.text(nextCountText);

                    $('.load-content-wrap').append(newProducts);

                    lazyLoadInstance.update(); /* eslint-disable-line no-undef */
                    initCustomPopover();
                    vouchers.initVouchers();

                    if (typeof dataLayer !== 'undefined') {
                        $(data).find('.product-wrap.load-content').each((index, element) => {
                            if (pushDataGA3) {
                                pushDataGA3.ecomm_prodid.push($(element).find('input[name="aid"]').first().val());
                                pushDataGA3.ecommerce.impressions.push($.parseJSON($(element).find('input[name="productjsondata"]').first().val()));
                            }

                            if (pushDataGA4) {
                                if (!pushDataGA4.items) {
                                    pushDataGA4.items = [];
                                }
                                pushDataGA4.item_list_id = $(element).find('input[name="aid"]').first().val();
                                pushDataGA4.items.push($.parseJSON($(element).find('input[name="productjsondataga4"]').first().val()));
                            }
                        });

                        if (pushDataGA3) {
                            dataLayer.push(pushDataGA3);
                        }

                        if (pushDataGA4) {
                            dataLayer.push(pushDataGA4);
                        }
                    }

                    if (!newNextLink) {
                        loadPageBtn.hide();
                    }

                    oxid.mask.off();
                })
                .fail(function () {
                    window.open(this.url, '_self');
                });
        }

        return false;
    });
});

function disableScroll(bodyClass, htmlClass) {
    $('body').toggleClass(bodyClass);
    $('html').toggleClass(htmlClass);
}

$(() => {
    const respoNavName = 'responsive-nav';
    const respoMenu = $('#js-respo-hamburger');
    const noScrollClass = '-disablescroll';
    let accessorySelector = '';
    let headerMenuSelector = '';
    const sidrActiveClass = 'sidr--active';
    const $responsiveNav = '#responsive-nav';

    if ($('[data-header-menu]').length) {
        headerMenuSelector = ', [data-header-menu]';
    }

    if ($('[data-product-accessories]').length) {
        accessorySelector = ', [data-accessories-aside]';
    }

    respoMenu.sidr({
        side: 'left',
        name: respoNavName,
        source: `[data-header-user]${headerMenuSelector}, [data-header-cms]${accessorySelector}`,
        renaming: false,
        displace: false,
        onOpen: () => {
            disableScroll(noScrollClass, noScrollClass);
            $(document).find($responsiveNav).addClass(sidrActiveClass);
        },
        onClose: () => {
            disableScroll(noScrollClass, noScrollClass);
            $(document).find($responsiveNav).removeClass(sidrActiveClass);
        },
    });

    $('.js-page-wrapper, .menu-close').on('click', () => {
        $.sidr('close', respoNavName);
    });
});

// Bestsellers more button
$(() => {
    const $collapsibleItems = $('#js-collapsible-items');

    $collapsibleItems.on('click', '.js-collapsible-items-btn', function () {
        $collapsibleItems.find('.item-hidden').fadeToggle(400, 'swing', () => {
            lazyLoadInstance.update(); /* eslint-disable-line no-undef */
        });
        $(this).toggleClass('open');
    });
});

$(() => {
    $('.filter-link').on('click', () => {
        oxid.mask.load();
    });
});

$(() => {
    $('.filter-link').on('click', () => {
        oxid.mask.load();
    });
});

lazyLoadInstance = new LazyLoad({ // eslint-disable-line no-undef
    elements_selector: 'img[data-src]',
    callback_loaded(el) {
        $(el).siblings('.loader').remove();
    },
    callback_error(el) {
        $(el).siblings('.loader').remove();
    },
});

$('body').on('shown.bs.modal', '.modal', () => {
    lazyLoadInstance.update(); /* eslint-disable-line no-undef */
});

$('body').on('shown.bs.collapse', '.collapse', () => {
    lazyLoadInstance.update(); /* eslint-disable-line no-undef */
});

$('body').on('shown.bs.dropdown', '.dropdown', () => {
    lazyLoadInstance.update(); /* eslint-disable-line no-undef */
});

$('body').on('shown.bs.dropdown', '.dropdown', () => {
    lazyLoadInstance.update(); /* eslint-disable-line no-undef */
});

$('.morepics').find('a').on('click', () => {
    lazyLoadInstance.update(); /* eslint-disable-line no-undef */
});

$(document.body).on('afterChange', '.slick-slider', () => {
    lazyLoadInstance.update(); /* eslint-disable-line no-undef */
});

$(document.body).on('init', '.slick-slider', () => {
    lazyLoadInstance.update(); /* eslint-disable-line no-undef */
});

$('*[data-small-basket-wrapper]').hover(() => {
    lazyLoadInstance.update(); /* eslint-disable-line no-undef */
});

/* Tooltip */
$(() => {
    $('[data-toggle="tooltip"]').tooltip();
});

/* Countdown */
$(() => {
    function limitedCountdown(element, hideElement) {
        const date = element.data('date');

        element.countdown(date, function (event) {
            const totalHours = event.offset.totalDays * 24 + event.offset.hours;

            $(this).html(event.strftime(`<span class="item">${totalHours} h</span> : <span class="item">%M m</span> : <span class="item">%S s</span>`));
        }).on('finish.countdown', function () {
            $(this).closest(hideElement).fadeOut();
        });
    }

    const limitedProduct = '.js-limited-product';

    /* Countdown homepage */
    $(limitedProduct).each(function () {
        const $limitedCountdown = $(this).find('.js-limited-countdown-wrapper');

        limitedCountdown($limitedCountdown, limitedProduct);
    });

    /* Countdown detail */

    const $detailLimitedCountDown = $('[data-detail-limited-countdown]');

    if ($detailLimitedCountDown.length > 0) {
        const $detailLimitedCountDownParent = $('[data-detail-limited-countdown-wrap]');

        limitedCountdown($detailLimitedCountDown, $detailLimitedCountDownParent);
    }
});

/* Aside slider */
$(() => {
    const limitedRotatorSettings = {
        dots: true,
        infinite: false,
        arrows: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: oxid.responsive.breakpointSm,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,

                },
            },
        ],
    };

    $('.js-limited-slider').each(function () {
        $(this).slick(limitedRotatorSettings);
    });
});

$(() => {
    const benefitsRotatorSettings = {
        dots: false,
        infinite: false,
        arrows: true,
        speed: 300,
        slidesToShow: 8,
        slidesToScroll: 1,
        variableWidth: true,
        responsive: [
            {
                breakpoint: oxid.responsive.breakpointLg,
                settings: {
                    slidesToShow: 5,
                    variableWidth: false,
                },
            },
            {
                breakpoint: oxid.responsive.breakpointMd,
                settings: {
                    slidesToShow: 5,
                    variableWidth: false,
                },
            },
            {
                breakpoint: oxid.responsive.breakpointSm,
                settings: {
                    slidesToShow: 4,
                    variableWidth: false,
                },
            },
            {
                breakpoint: oxid.responsive.breakpointXs,
                settings: {
                    slidesToShow: 3,
                    arrows: false,
                    dots: true,
                    variableWidth: false,
                },
            },
        ],
    };

    const prefooterBenefitsSlider = $('.js-prefooter-benefits-slider');
    const prefooterItem = prefooterBenefitsSlider.find('.prefooter-item');

    function initPrefooterBenefitsSlider(slider, settings) {
        slider.each(function () {
            $(this).slick(settings);
        });
    }

    if (oxid.responsive.xl.matches && prefooterItem.length > 8) {
        initPrefooterBenefitsSlider(prefooterBenefitsSlider, benefitsRotatorSettings);
    } else if (!oxid.responsive.xl.matches) {
        initPrefooterBenefitsSlider(prefooterBenefitsSlider, benefitsRotatorSettings);
    }

    $(window).on('resize', () => {
        if (!prefooterBenefitsSlider.hasClass('slick-initialized') && !oxid.responsive.xl.matches) {
            initPrefooterBenefitsSlider(prefooterBenefitsSlider, benefitsRotatorSettings);
        } else if (prefooterBenefitsSlider.hasClass('slick-initialized') && oxid.responsive.xl.matches && prefooterItem.length <= 8) {
            prefooterBenefitsSlider.each(function () {
                $(this).slick('unslick');
            });
        }
    });
});

$(() => {
    if (oxid.responsive.xs.matches || oxid.responsive.sm.matches) {
        $('#js-footer').on('click', '.js-footer-headline', function () {
            $(this).toggleClass('active');
            $(this).parent().children('.js-footer-nav-wrap').slideToggle();
        });
    }
});

// Respo menu
$(() => {
    $(document).find('.sidr').on('click', '.js-nav-item-arrow', function (e) {
        e.preventDefault();

        $(this).toggleClass('active');
        $(this).closest('.js-nav-item-toggle').next('.js-subcats').slideToggle();
    });
});

// Category menu
$(() => {
    $('#js-header-responsive-btn').on('click', function () {
        $(this).toggleClass('active');
        $('#js-main-menu').toggleClass('d-lg-none');
    });
});

// SVG to inline SVG
$(() => {
    $('.js-svg').each(function () {
        const $img = jQuery(this);
        const imgID = $img.attr('id');
        const imgClass = $img.attr('class');
        const imgURL = $img.attr('src');

        $.get(imgURL, (data) => {
            // Get the SVG tag, ignore the rest
            let $svg = $(data).find('svg');

            // Add replaced image's ID to the new SVG
            if (typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            // Add replaced image's classes to the new SVG
            if (typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', `${imgClass} replaced-svg`);
            }

            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr('xmlns:a');

            // Check if the viewport is set, else we gonna set it if we can.
            if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                $svg.attr('viewBox', `0 0 ${$svg.attr('height')} ${$svg.attr('width')}`);
            }

            // Replace image with new SVG
            $img.replaceWith($svg);
        }, 'xml');
    });
});

// More info toggle
$(() => {
    const moduleToggleShowActiveBtnClass = 'module-toggle-show-btn-active';
    const moduleToggleShowFullHeight = 'module-toggle-show-full-height';
    const moduleToggleShowWrap = '.js-module-toggle-show';
    const moduleToggleShowMoreBtn = '.js-module-toggle-show-more';
    const moduleToggleShowGradientClass = 'module-toggle-show-gradient';
    const moduleToggleShowDescription = '.js-module-toggle-show-description';
    const moduleToggleShowBtnShow = 'module-toggle-show-btn-show';
    const moduleToggleShowLimitedHeightClass = 'module-toggle-show-limited-height';
    const moduleToggleShowMaxHeightCss = 'max-height';
    const moduleToggleShowIndentation = 118;

    function moduleToggleShowDisplayBtn(moduleToggleShowDescriptionHeigh, moduleToggleShowMaxHeigh) {
        if (moduleToggleShowDescriptionHeigh > moduleToggleShowMaxHeigh) {
            return true;
        }

        return false;
    }

    function moduleToggleShowInit() {
        $(moduleToggleShowWrap).each(function () {
            const $moduleToggleShowDescription = $(this).find(moduleToggleShowDescription);
            const moduleToggleShowHeight = $(this).find(moduleToggleShowDescription).outerHeight();
            const moduleToggleShowMaxHeight = $(this).data(moduleToggleShowMaxHeightCss);
            const moduleToggleShowBtnStatus = moduleToggleShowDisplayBtn(moduleToggleShowHeight, moduleToggleShowMaxHeight);

            if (moduleToggleShowBtnStatus) {
                $(this).find($moduleToggleShowDescription).css(moduleToggleShowMaxHeightCss, moduleToggleShowMaxHeight);
                $(this).find($moduleToggleShowDescription).addClass(moduleToggleShowLimitedHeightClass);
                $(this).find(moduleToggleShowMoreBtn).addClass(moduleToggleShowBtnShow);
                $(this).removeAttr('style');
            }
        });
    }

    $(moduleToggleShowMoreBtn).on('click', function (e) {
        if ($(this).is('a')) {
            return;
        }

        if ($(this).hasClass(moduleToggleShowActiveBtnClass)) {
            const descriptionPosition = $(this).closest(moduleToggleShowWrap).offset().top - moduleToggleShowIndentation;

            if (window.scrollY > $(moduleToggleShowWrap).offset().top) {
                $(window).scrollTop(descriptionPosition);
            }
        }

        e.preventDefault();
        const $moduleToggleShowDescription = $(this).closest(moduleToggleShowWrap).find(moduleToggleShowDescription).eq(0);
        const moduleToggleShowGradient = $(this).data('gradient');

        if (moduleToggleShowGradient) {
            $moduleToggleShowDescription.toggleClass(moduleToggleShowGradientClass);
        }

        $moduleToggleShowDescription.toggleClass(moduleToggleShowFullHeight);
        $(this).toggleClass(moduleToggleShowActiveBtnClass);
    });

    $('.detail-tabs-link').first().tab('show');
    moduleToggleShowInit();

    $('a[data-toggle="tab"]').on('shown.bs.tab', () => {
        moduleToggleShowInit();
    });

    $('.collapse').on('shown.bs.collapse', () => {
        moduleToggleShowInit();
    });
});

// Scroll to element
$(() => {
    $('*[data-scroll-to-element]').on('click', function (e) {
        e.preventDefault();

        const delay = $(this).data('delay');
        const tabs = $(this).attr('href');
        const tabLink = $(this).data('scroll-to-element-tab');
        const collapseLink = $(this).data('scroll-to-element-collapse');

        $(tabLink).tab('show');
        $(collapseLink).collapse('show');

        $([document.documentElement, document.body]).animate({
            scrollTop: $(tabs).offset().top,
        }, delay);
    });
});

/* Share */
$(() => {
    function mergeShareUrl(shareDefaultUrl) {
        $('[data-extend-url]').each(function () {
            const shareUrl = $(this).data('extend-url');
            $(this).attr('href', shareUrl + shareDefaultUrl);
        });
    }

    $('*[data-share]').each(function () {
        const shareDefaultUrl = $(this).data('share-url');
        mergeShareUrl(shareDefaultUrl);
    });

    $('[data-target="#share-modal"]').on('click', function () {
        const productUrl = $(this).data('product-url');

        mergeShareUrl(productUrl);
    });
});

// Small basket
$(() => {
    if (oxid.responsive.lg.matches || oxid.responsive.xl.matches) {
        const $smallBasketBox = $('[data-small-basket-box]');
        const $smallBasketBoxLink = $('[data-small-basket-link]');

        $(document).on('click', '[data-small-basket-link]', (e) => {
            e.preventDefault();

            $smallBasketBox.toggle();
        });

        $(document).on('click', (event) => {
            if ($smallBasketBox.is(':visible') && !$smallBasketBox.is(event.target) && $smallBasketBox.has(event.target).length === 0 && !$('[data-small-basket-link]').is(event.target) && $('[data-small-basket-link]').has(event.target).length === 0) {
                $('[data-small-basket-box]').toggle();
            }
        });
    }

    let timer;

    function updateBasket(delay) {
        clearTimeout(timer);
        const $headerBasketInner = $('*[data-small-basket]');
        const basketPath = $('*[data-small-basket-path]')[0].dataset.smallBasketPath;
        const $headerBasket = $('*[data-small-basket-wrapper]');
        const $headerBasketForm = $('*[data-small-basket-form]');
        const overlayClass = '-overlay';

        timer = setTimeout(() => {
            $headerBasketInner.addClass(overlayClass);

            $.ajax({
                url: basketPath,
                data: $headerBasketForm.serialize(),
                method: 'POST',
            })
                .done((data) => {
                    if (data.smarty) {
                        $headerBasket.find('[data-small-basket]').replaceWith($(data.smarty).find('[data-small-basket]'));
                        $headerBasket.find('[data-small-basket-link]').replaceWith($(data.smarty).find('[data-small-basket-link]'));

                        initSpinner();

                        $headerBasketInner.removeClass(overlayClass);
                    }
                });
        }, delay);
    }

    $(document).on('change', '*[data-small-basket-quantity]', () => {
        updateBasket(1000);
    });

    $(document).keypress((e) => {
        if (e.which === 13) {
            e.preventDefault();
            updateBasket(0);
        }
    });

    $(document).on('click', '*[data-small-basket-remove]', function (e) {
        e.preventDefault();

        const $product = $(this).closest('*[data-small-basket-product]');
        const $amountInput = $product.find('*[data-small-basket-quantity]');

        $amountInput.val(0);
        $amountInput.change();
    });
});

$(() => {
    $(document).on('click', '[data-password-show]', function (e) {
        e.preventDefault();

        const input = $(this).closest('[data-password-wrap]').find('[data-password-input]');

        if (input.attr('type') === 'password') {
            input.attr('type', 'text');
        } else {
            input.attr('type', 'password');
        }
    });
});

$(() => {
    $('[data-order-remark]').on('keyup paste', function (e) {
        const textareaValue = $(this).val();
        const textareaValueLength = textareaValue.length;
        const $textareaLength = $('[data-length]');
        const $textareaLengthWrap = $('[order-length-wrap]');
        const textareaLimit = $textareaLength.data('length');
        const textareaRemain = parseInt(textareaLimit - textareaValueLength, 10);
        const warningClass = '-warning';

        if (textareaRemain < 0 && e.which !== 0 && e.code !== 0) {
            $(this).val((textareaValue).substring(0, textareaValueLength - 1));
        } else {
            if (textareaRemain === 0) {
                $textareaLengthWrap.addClass(warningClass);
            } else {
                $textareaLengthWrap.removeClass(warningClass);
            }
            $textareaLength.text(`${textareaRemain}/${textareaLimit}`);
        }
    });
});

// Show tooltip on favourite link
$(() => {
    const $favouriteLink = $('.js-tooltip-timeout');
    const favouriteLinkTime = 3000;

    $favouriteLink.tooltip('show');
    setTimeout(() => {
        $favouriteLink.tooltip('dispose');
    }, favouriteLinkTime);
});

$(() => {
    const $headerMiddle = $('[data-header-middle]');
    const headerMiddleClass = '-search-collapsed';

    $('#js-header-search').on('show.bs.collapse', () => {
        $headerMiddle.addClass(headerMiddleClass);
    }).on('hide.bs.collapse', () => {
        $headerMiddle.removeClass(headerMiddleClass);
    });
});

// Subcategories
$(() => {
    const $subcategories = $('[data-subcategories]');

    $subcategories.on('click', '[data-subcategories-btn]', function () {
        $subcategories.find('[data-subcategories-item]').toggleClass('d-none');
        $(this).toggleClass('active');
    });
});

// Checkbox collapse

$(() => {
    const $toggleWrap = $('[data-toggle-wrap]');

    $toggleWrap.on('change', '[data-toggle-input]', function () {
        const $collapse = $(this).closest('[data-toggle-wrap]').find('[data-toggle-target]');

        if ($(this).is(':checked')) {
            $collapse.slideDown('fast');
        } else {
            $collapse.slideUp('fast');
        }
    });
});


$(() => {
    $(document).on('submit', '[data-add-product-to-cart]', function (e) {
        const $form = $(e.target);

        oxid.popup.load();

        e.preventDefault();

        $.ajax({
            url: this.action,
            data: $(this).serialize(),
            method: 'POST',
        })
            .always(() => {
                oxidDataLayer.addToCart($form);

                window.location.reload();
            });
    });
});

// Newsletter modal

$(() => {
    const localStorageName = 'newsletterCountDown';
    const modalCookie = 'ModalCookie';
    const $modalNewsletter = $('[data-modal-newsletter]');
    const timeoutLocalStorage = localStorage.getItem(localStorageName);
    let limit = timeoutLocalStorage || 15; // 15 sec.
    const newsletterCountDownCookie = readCookie(modalCookie);
    const limitTime = 1000;

    function countDown() {
        limit -= 1;

        if (limit > 0) {
            setTimeout(countDown, limitTime);
        }

        if (limit === 0) {
            $modalNewsletter.modal('show');
            localStorage.removeItem(localStorageName);
        }

        return limit;
    }

    if (!newsletterCountDownCookie) {
        setTimeout(countDown, limitTime);

        $(window).bind('beforeunload', () => {
            const timeout = countDown();

            if (timeout > 0) {
                localStorage.setItem(localStorageName, timeout);
            }
        });

        $modalNewsletter.on('hidden.bs.modal', () => {
            createCookie(modalCookie, 1, 2629800);
        });
    }
});

// Fake radio
$(() => {
    $('[data-selection-basket-fake-radio]').on('change', function (e) {
        const fakeRadio = $(this).data('selection-basket-fake-radio');

        if (fakeRadio.length && (fakeRadio === 'cart' || fakeRadio === 'basket' || fakeRadio === 'detail')) {
            e.preventDefault();
            $(this).closest('[data-selection-basket-wrapper]').find('[data-selection-basket-fake-radio]').not(this)
                .prop('checked', false);
        }

        if (fakeRadio.length && $('*[data-basket-summary-form]').length && (fakeRadio === 'cart' || fakeRadio === 'basket' || fakeRadio === 'more-optional')) {
            oxid.popup.load();
            $('*[data-basket-summary-form]').submit();
        }
    });
});


$(() => {
    const productDemandForm = $('*[data-product-demand-form]');
    const $demandModal = $('[data-demand-modal]');
    const $demandMain = $('[data-demand-main]');
    const $demandSuccess = $('[data-demand-success]');
    const $demandToHide = $('[data-demand-hide]');
    const demandProductIdSelector = '[data-demand-product-id]';
    const demandProductTitleSelector = '[data-demand-product-title]';

    $demandModal.on('show.bs.modal', (e) => {
        const $demandProduct = $(e.relatedTarget);
        if ($demandProduct.is('[data-demand-from-list]')) {
            const demandProductTitle = $demandProduct.data('demandProductTitle');
            const demandProductId = $demandProduct.data('demandProductId');

            $demandModal.find(demandProductIdSelector).val(demandProductId);
            $demandModal.find(demandProductTitleSelector).text(demandProductTitle);
        }
    });

    $demandModal.on('hidden.bs.modal', () => {
        $demandMain.fadeIn();
        $demandSuccess.fadeOut();
    });

    productDemandForm.submit((e) => {
        e.preventDefault();

        $.ajax({
            url: productDemandForm.data('url'),
            method: 'POST',
            data: productDemandForm.serialize(),
            success() {
                // dekovaci stranka
                $demandToHide.fadeOut();
                $demandMain.fadeOut();
                $demandSuccess.fadeIn();
                productDemandForm.trigger('reset');
            },
        });
    });
});

$(() => {
    if (typeof intlTelInput !== 'undefined') {
        const input = document.querySelector('input[name="invadr[oxuser__oxfoncountrycode]"]');

        intlTelInput(input, { /* eslint-disable-line no-undef */
            nationalMode: false,
            autoHideDialCode: false,
            initialCountry: 'auto',
            geoIpLookup(success, failure) { // eslint-disable-line no-unused-vars
                $.get('https://ipinfo.io', () => {}, 'jsonp').always((resp) => {
                    const countryCode = (resp && resp.country) ? resp.country : 'us';
                    success(countryCode);
                });
            },
        });
    }
});

// Floating banner
$(() => {
    const classOpen = 'open';
    const classHover = 'hover';
    const $banner = $('[data-floating-banner]');

    $('[data-floating-banner-button]').on('click', function () {
        const $closestBanner = $(this).closest($banner);

        if (!$closestBanner.hasClass(classOpen)) {
            $closestBanner.addClass(classOpen);
        } else {
            $closestBanner.removeClass(classOpen);
        }
    });

    $banner.hover(function () {
        $(this).addClass(classHover);
    }, function () {
        $(this).removeClass(classHover);
    });

    $(document).on('click', () => {
        if ($banner.hasClass(classOpen) && !$banner.hasClass(classHover)) {
            $banner.removeClass(classOpen);
        }
    });
});

// Header info alert
$(() => {
    const $headerInfoAlert = $('[data-header-info-alert]');
    $headerInfoAlert.on('click', '[data-dismiss-btn]', () => {
        const timeInSeconds = 604800;

        createCookie('headerInfoAlert', 'hide', timeInSeconds);
    });
});

$(() => {
    const toggleBtn = '[data-review-toggle-btn]';
    const description = '[data-review-toggle-description]';

    $('[data-review-toggle]').each(function () {
        const $toggleDescription = $(this).find(description);
        const descriptionHeight = $toggleDescription.outerHeight();
        const descriptionMaxHeight = $(this).data('review-toggle-height');

        if (descriptionMaxHeight >= descriptionHeight) {
            return;
        }

        $toggleDescription.css('max-height', descriptionMaxHeight);
        $(this).find(toggleBtn).show();
    });

    $(toggleBtn).on('click', function () {
        $(this).closest('[data-review-toggle]').find(description).toggleClass('review-toggle__description--active');
        $(this).toggleClass('review-toggle-more--active');
    });
});

function selectPayment() {
    var $payForm=$('#payform');
    $payForm.append('<input type="hidden" name="changePaymentType" value="1" />');
    $payForm.submit();
}

$(document).ready(function() {
    if ($('[data-homecredit-calculator-open]').data('homecredit-calculator-open')) {
        showCalc()
    }

    $(document).on('click', 'hc-calc', function() {
        if ($('.js-homecredit').data('location') === 'detail') {
            return
        }

        setTimeout(function() {
            var modalExistsAfter = $('hc-calc').length > 0;

            if (!modalExistsAfter && !HCInstallmentsSelected) {
                oxid.mask.load();

                toast.showToast()

                setTimeout(function() {
                    try {
                        $('[name="paymentid"]').first().prop('checked', true);
                        selectPayment()
                    } catch {
                    }
                }, 1000);
            }
        }, 500);
    });
});

$(() => {
    const flipCookie = 'FlipCookie';
    const foldCookie = 'FoldCookie';
    const $flipPopup = $('[data-flip-popup]');
    const $foldPopup = $('[data-fold-popup]');

    $flipPopup.on('click', 'a', () => {
        createCookie(flipCookie, 1, 2629800);
    });

    $flipPopup.on('hidden.bs.modal', () => {
        createCookie(flipCookie, 1, 2629800);
    });

    $foldPopup.on('click', 'a', () => {
        createCookie(flipCookie, 1, 2629800);
    });

    $foldPopup.on('hidden.bs.modal', () => {
        createCookie(foldCookie, 1, 2629800);
    });
});
